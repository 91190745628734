<template>
  <form @submit.prevent="onSubmit()">

    <div class="view-header" v-if="pageTitle">
      <div class="view-header__footer">
        <Btn
          class="back-btn"
          round
          grow
          icon="arrow-left"
          :to="{ 'name' : previousUrl }"
        />
        <h1 class="page-title" v-html="pageTitle"></h1>
      </div>
    </div>

    <div class="view-body">
      <Section>
        <Container>

          <div class="grid" v-if="!loading">

            <div class="tmp-col">
              <template v-if="typeof $route.params.id !== 'undefined'">
                <KeyValue label="Nom" :value="entity.nom" />
                <KeyValue
                  label="Type d'entité"
                  v-if="entity.type"
                  :value="entity.type.designation"
                />
                <KeyValue label="Entité parente" :value="entity.parent?.nom" />
              </template>
              <template v-else>
                <Input label="Nom" id="nom" required />
                <SelectExtended
                  @update:modelValue="selectEntity($event)"
                  id="type_uid"
                  label="Type d'entité"
                  option-key="uid"
                  option-value="designation"
                  api-endpoint="dictionnaire/type/entite"
                  required
                />
                <SelectExtended
                  :key="componentKey"
                  v-if="Object.keys(entities).length"
                  id="parent_id"
                  label="Entité parente"
                  option-key="id"
                  option-value="nom"
                  :items="entities"
                  :apiParams="Object.keys(entities).length
                    ? { filters: `type.id:in(${entityType.key})` }
                    : null
                  "
                />
              </template>
              <Input label="Adresse" id="adresse" />
              <Input label="Code postal" id="cp" />
              <Input label="Ville" id="ville" />
              <SelectExtended
                v-if="api.endpoint"
                id="pays"
                label="Pays"
                option-key="id"
                option-value="valeur"
                :api-endpoint="api.endpoint"
                :apiParams="{sort:'valeur.ASC'}"
              />
              <Input type="textarea" label="Description" id="description" />
            </div>

            <div class="tmp-col">
              <InputImage
                id="logo_prim_id"
                label="Logotype principal"
                type="logo"
                textInfo="Fichier au format JPG, PNG ou SVG, 2Mo maximum"
                :media="entity.logo_prim ?? null"
                @update:modelValue="setMedia('logo_prim_id', $event)"
                defaultImage
              />

              <InputImage
                id="logo_sec_id"
                label="Logotype secondaire"
                type="logo"
                textInfo="Fichier au format JPG, PNG ou SVG, 2Mo maximum"
                :media="entity.logo_sec ?? null"
                @update:modelValue="setMedia('logo_sec_id', $event)"
                defaultImage
              />

              <InputImage
                id="logo_ter_id"
                label="Logotype secondaire 2"
                type="logo"
                textInfo="Fichier au format JPG, PNG ou SVG, 2Mo maximum"
                :media="entity.logo_ter ?? null"
                @update:modelValue="setMedia('logo_ter_id', $event)"
                defaultImage
              />
            </div>

          </div>

        </Container>
      </Section>
    </div>

    <div class="view-footer">
      <div class="view-actions view-actions--left">
        <Btn
          text="Annuler"
          :to="{ 'name' : previousUrl }"
        />
        <Btn
          v-if="action === 'edit' && helperService.userHasPermission('entite_remove')"
          text="Supprimer"
          @click="modalActive = true"
          hollow

        />
        <Btn
          btnType="submit"
          :text="action === 'edit' ? 'Modifier' : 'Sauvegarder'"
          color="primary"
        />
      </div>
    </div>

  </form>

  <!-- Modals modalDelete-->
  <Modal
    title="Supprimer l'entité ?"
    :active="modalActive"
    @modal-close="modalActive = false"
  >
    <template v-slot:modal-body>
      <p>
        Voulez vous vraiment supprimer l'entité <b>{{ entity.nom }}</b>&nbsp;?
      </p>
    </template>
    <template v-slot:modal-footer>
      <Btn text="Annuler" @click="modalActive = false" />
      <Btn
        text="Supprimer"
        @click="deleteEntity()"
        icon="trash-alt"
        color="primary"
      />
    </template>
  </Modal>
</template>

<script>
import Section from '@/components/layout/Section.vue'
import Container from '@/components/layout/Container.vue'
import KeyValue from '@/components/form/KeyValue.vue'
import Input from '@/components/form/Input.vue'
import Btn from '@/components/base/Btn.vue'
import SelectExtended from '@/components/form/SelectExtended.vue'
import InputImage from '@/components/form/InputImage.vue'
import Modal from '@/components/layout/Modal.vue'

export default {
  name: 'MyEntityAddEditView',

  components: {
    Modal,
    InputImage,
    SelectExtended,
    Btn,
    Input,
    KeyValue,
    Container,
    Section,
  },

  props: {
    pageTitle: {
      type: String,
    },
  },

  data() {
    // Define a validation schema
    const validationSchema = this.yup.object().shape({
      // Only on add
      ...(typeof this.$route.params.id === 'undefined' && {
        nom: this.yup.string().required(),
        type_uid: this.yup.object().required(),
        parent_id: this.yup.object(),
      }),
      adresse: this.yup.string().nullable(),
      cp: this.yup.number().nullable(),
      ville: this.yup.string().nullable(),
      pays: this.yup.object().nullable(),
      description: this.yup.string().nullable(),
      logo_prim_id: this.yup.object().nullable(),
      logo_sec_id: this.yup.object().nullable(),
      logo_ter_id: this.yup.object().nullable(),
    })

    const { errors, isSubmitting } = this.formService.initFrom(
      validationSchema,
    )
    const onSubmit = this.formService.handleSubmit((values) => {
      if (this.action === 'edit') {
        this.updateEntity(values)
      } else {
        this.createEntity(values)
      }
    })

    return {
      api: {
        endpoint: '',
      },
      entity: [],
      entityId: this.$route.params.id ?? this.$store.state.auth.user.data.entite.id,
      action: this.$route.params.action,
      country: [],
      data: [],
      entities: {},
      entityType: {},
      componentKey: 0,
      modalActive: false,
      loading: false,
      onSubmit,
      errors,
      isSubmitting,
    }
  },

  computed: {
    previousUrl() {
      return typeof this.$route.params.id !== 'undefined' ? 'entities' : 'myEntity'
    },
  },

  watch: {
    entityType() {
      if (this.entityType.key === 'CENTRALEACHATNATIONALE') {
        this.entities = {}
      } else {
        this.fetchService.get('entite', { limit: 0 }).then((response) => {
          let entity

          if (this.entityType.key === 'COOPERATIVE') {
            entity = response.data.filter((data) => data.type.uid === 'UNIONCOOPERATIVE'
              || data.type.uid === 'CENTRALEACHATNATIONALE'
              || data.type.uid === 'CENTRALEACHAT')
          }
          if (this.entityType.key === 'UNIONCOOPERATIVE') {
            entity = response.data.filter(
              (data) => data.type.uid === 'CENTRALEACHAT',
            )
          }
          if (this.entityType.key === 'CENTRALEACHAT') {
            entity = response.data.filter(
              (data) => data.type.uid === 'CENTRALEACHATNATIONALE',
            )
          }

          this.entities = entity

          this.componentKey += 1
        })
      }
    },
  },

  mounted() {
    if (
      typeof this.$route.params.id !== 'undefined'
      || (this.previousUrl === 'myEntity' && this.action === 'edit')
    ) {
      this.getData()
    }
  },

  methods: {
    setMedia(field, event) {
      this.data[field] = event
    },

    createEntity(values) {
      // eslint-disable-next-line no-param-reassign
      values.type_uid = this.entityType.key
      // eslint-disable-next-line no-param-reassign
      values.parent_id = values.parent_id?.key
      // eslint-disable-next-line no-param-reassign
      values.logo_prim_id = values.logo_prim_id?.id || this.data?.logo_prim_id
      // eslint-disable-next-line no-param-reassign
      values.logo_sec_id = values.logo_sec_id?.id || this.data?.logo_sec_id
      // eslint-disable-next-line no-param-reassign
      values.logo_ter_id = values.logo_ter_id?.id || this.data?.logo_ter_id

      this.fetchService.post('entite', values).then(() => {
        this.emitter.emit('alert', {
          type: 'success',
          content: 'L\'entité a bien été créée.',
        })

        this.$store.dispatch('auth/refreshUser')

        this.$router.push({ name: 'entities' })
      },
      (responseError) => {
        this.formService.handleApiError(responseError)
      })
    },

    selectEntity(event) {
      this.entityType = event
    },

    updateEntity(values) {
      // eslint-disable-next-line no-param-reassign
      values.nom = this.entity.nom
      // eslint-disable-next-line no-param-reassign
      values.type_uid = this.entity.type.uid
      // eslint-disable-next-line no-param-reassign
      values.parent_id = this.entity.parent?.id
      // eslint-disable-next-line no-param-reassign
      values.pays_id = this.entity.pays?.id ?? values.pays?.key ?? null
      // eslint-disable-next-line no-param-reassign
      values.logo_prim_id = values.logo_prim_id?.id ?? null
      // eslint-disable-next-line no-param-reassign
      values.logo_sec_id = values.logo_sec_id?.id ?? null
      // eslint-disable-next-line no-param-reassign
      values.logo_ter_id = values.logo_ter_id?.id ?? null

      this.fetchService.put(`entite/${this.entityId}`, values).then(() => {
        this.emitter.emit('alert', {
          type: 'success',
          content: 'L\'entité a bien été modifiée.',
        })

        this.$router.push({
          name: this.$route.params.id ? 'entity' : 'myEntity',
          params: {
            ...(this.$route.params.id && { id: this.entityId }),
          },
        })
      },
      (responseError) => {
        this.formService.handleApiError(responseError)
      })
    },

    deleteEntity() {
      this.fetchService.delete(`entite/${this.entityId}`).then(() => {
        this.modalActive = false

        this.emitter.emit('alert', {
          type: 'success',
          content: 'L\'entité a bien été supprimée.',
        })

        this.$store.dispatch('auth/refreshUser')

        this.$router.push({ name: 'entities' })
      })
    },

    getData() {
      this.emitter.emit('open-loader')
      this.fetchService.get(`entite/${this.entityId}`).then((response) => {
        this.entity = response.data

        this.schema = this.formService.populateShema(this.entity)

        if (this.entity.type) {
          this.schema.type_uid = {
            key: this.entity.type.id,
            value: this.entity.type.designation,
          }
        }

        if (this.entity.logo_prim) {
          this.schema.logo_prim_id = this.entity.logo_prim
        }
        if (this.entity.logo_sec) {
          this.schema.log_sec_id = this.entity.logo_sec
        }
        if (this.entity.logo_ter) {
          this.schema.logo_ter_id = this.entity.logo_ter
        }

        if (this.entity.pays) {
          this.schema.pays = { key: this.entity.pays.id, value: this.entity.pays.uid }
        }

        this.formService.setFormValues(this.schema)
      })

      this.fetchService.get('dictionnaire/referentiel', { limit: 0 }).then((response) => {
        const data = response.data.filter((item) => item.uid === 'pays')

        this.api.endpoint = `dictionnaire/referentiel/${data[0].id}/valeur`
        this.emitter.emit('close-loader')
      })
    },
  },
}
</script>

<style scoped>

</style>
