<template>
  <div
    class="input-block input-image"
    :class="{
      'input-block--required': required,
      'input-block--disabled': disabled,
      'input-block--error': errorMessage,
      'input-block--inline': inline,
    }"
  >
    <label :for="id" v-if="label !== undefined && label !== ''">{{
        label
      }}</label>
    <div
      class="input"
      :class="getClasses"
    >
      <template v-if="!loading">
        <UserAvatar
          v-if="type === 'avatar'"
          :key="componentKey"
          class="h-margin-auto"
          :image="image ?? null"
          :initials="avatarInitials"
          :size="avatarSize"
          :color="avatarColor"
        />
        <template v-else>
          <ImageToken
            :image="image"
            :alt="mediaKey"
            :key="componentKey"
            :defaultImage="defaultImage"
            class="h-margin-auto"
          />
        </template>
      </template>
      <template v-else>
        <div class="fixed-ratio fixed-ratio--2by1" style="position: relative; width: 100%">
          <Loader active />
        </div>
      </template>

      <div class="input-image-filename">
        <span>{{ image ? image.nom : 'Aucun fichier choisi' }}</span>
        <Btn
          class="input-image-btn-remove"
          v-if="imageUploaded"
          :key="componentKey"
          @click="deleteImage"
          color="default"
          icon="times-circle"
          size="xs"
          grow
          round
        />
      </div>

      <!-- Drag & drop -->
      <div
        class="drop"
        :class="getClasses"
        @dragover.prevent="dragOver"
        @dragleave.prevent="dragLeave"
        @drop.prevent="select($event)"
      >
        <h1 style="margin-bottom: 0">Glisser-déposer votre image ici</h1>
      </div>
      <div class="input-image-label">
        <div class="input">
          <label class="input-image-label">
            <input
              class="input-image-input"
              v-if="!image || (image && $route.params.id) || avatarImage || media"
              :id="id"
              type="file"
              ref="inputFile"
              accept="image/*"
              :name="name ? name : id"
              :disabled="disabled"
              @change="select"
            />
            <Btn
              class="input-image-btn-add"
              :text="loading ? 'En cours de téléversement...' : 'Choisir un fichier'"
              color="default"
              icon="plus"
              :disabled="loading"
            />
          </label>

          <div class="input-text input-text--info" v-if="textInfo && !errorMessage">
            {{ textInfo }}
          </div>
          <div class="input-text input-text--error" v-if="errorMessage">
            {{ errorMessage }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import UserAvatar from '@/components/user/UserAvatar.vue'
import Btn from '@/components/base/Btn.vue'
import { useField } from 'vee-validate'
import Loader from '@/components/layout/Loader.vue'
import ImageToken from '@/components/base/ImageToken.vue'
import axios from 'axios'

export default {
  name: 'InputImage',

  components: {
    ImageToken,
    Loader,
    Btn,
    UserAvatar,
  },

  props: {
    id: {
      type: String,
      default: 'input-image-upload',
    },
    // Entity, logo, picto etc.
    media: {
      type: Object,
    },
    // Type of upload (avatar, logo, picto)
    type: {
      type: String,
      default: 'avatar',
    },
    // Property to be updated
    mediaKey: {
      type: String,
      default: 'logo_prim_id',
    },
    // Avatar
    avatarImage: {
      type: Object,
    },
    avatarSize: {
      type: String,
      default: 'normal',
    },
    avatarColor: {
      type: [String, Number],
      default: '18',
    },
    avatarInitials: {
      type: String,
    },
    // Button
    buttonText: {
      type: String,
      default: 'Supprimer le fichier',
    },
    // Input
    name: {
      type: String,
    },
    label: {
      type: String,
    },
    placeholder: {
      type: String,
      default: ' ',
    },
    required: {
      type: Boolean,
      default: false,
    },
    accept: {
      type: String,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    textInfo: {
      type: String,
    },
    textError: {
      type: String,
    },
    inline: {
      type: Boolean,
      default: false,
    },
    defaultImage: {
      type: Boolean,
      default: false,
    },
  },

  data(props) {
    const {
      errorMessage,
      handleBlur,
      handleChange,
    } = useField(props.id)

    return {
      errorMessage,
      handleBlur,
      handleChange,
      chunks: [],
      chunkSize: 0,
      numberOfChunks: 0,

      // Data updated to perform a component reload
      index: 1,
      componentKey: 0,

      // Object
      image: null,
      // Keep the image is if the initial in the props
      initialImage: true,
      // Data of the uploaded image
      imageData: {},
      // Determine if the image is already uploaded
      imageUploaded: false,
      loading: false,

      isDragging: false,
    }
  },

  computed: {
    formData() {
      const chunks = Math.ceil((this.image.size / 1024) / 1024)

      const data = {
        morceau: this.chunks[0],
        position: this.index,
        meta: {
          nom: this.image.nom,
          taille: Math.round(this.image.size / 1024),
          extension: this.image.name.split('.').pop().toLowerCase(),
          longueur: chunks,
          type_uid: this.type,
        },
      }

      return this.fileService.setData(data)
    },
    config() {
      return {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${this.$store.state.auth.user.token}`,
        },
        data: this.formData,
      }
    },
    getClasses() {
      return {
        isDragging: this.isDragging,
        isUploaded: this.imageUploaded,
      }
    },
  },

  watch: {
    // eslint-disable-next-line func-names
    '$props.media': function () {
      if (!this.imageUploaded) {
        this.image = this.media ?? null
        this.imageUploaded = true

        this.updateInput(this.image)
      }
    },
  },

  mounted() {
    if (this.type !== 'avatar') {
      this.image = this.media || null

      if (this.image) {
        this.imageUploaded = true
      }
    }
  },

  // For avatar
  updated() {
    if (this.type === 'avatar') {
      this.image = this.avatarImage || null

      if (this.image) {
        this.imageUploaded = true
      }
    }
  },

  methods: {
    dragOver() {
      this.isDragging = true
    },
    dragLeave() {
      this.isDragging = false
    },
    select(event) {
      this.isDragging = false

      if (this.imageUploaded) {
        this.deleteImage(false)
      }

      this.image = 'dataTransfer' in event ? event.dataTransfer.files[0] : event.target.files.item(0)
      this.image.nom = this.image.name
      delete this.image.name

      const alert = (text) => this.emitter.emit('alert', {
        type: 'warning',
        content: text,
      })

      const checkUploadedFile = (fileSizeInMegabytes, validExtensions) => {
        if (this.image.size > this.fileService.formatMegaBytes(fileSizeInMegabytes)) {
          alert(`La taille du fichier ne peut pas être supérieure à ${fileSizeInMegabytes}Mo`)
        } else if (!this.fileService.hasExtensions(this.image, validExtensions)) {
          alert(`L'extension est invalide, le type de fichier attendu est : ${validExtensions.join(', ')}`)
        } else {
          this.uploadImage()
        }
        this.resetForm()
      }
      switch (this.type) {
        case 'avatar':
        default:
          checkUploadedFile(1, ['jpg', 'jpeg', 'png'])
          break
        case 'logo':
          checkUploadedFile(2, ['jpg', 'jpeg', 'png', 'svg'])
          break
        case 'picto':
          checkUploadedFile(1, ['jpg', 'jpeg', 'png', 'svg'])
          break
        case 'photo':
          checkUploadedFile(5, ['jpg', 'jpeg'])
      }
    },
    uploadImage() {
      this.chunkSize = Math.min(this.image.size / 1024, 1024)
      this.numberOfChunks = Math.ceil((this.image.size / 1024) / this.chunkSize)

      this.loading = true
      for (let i = 0; i < this.numberOfChunks; i += 1) {
        this.chunks.push(this.image.slice(
          i * (this.chunkSize * 1024),
          (i + 1) * (this.chunkSize * 1024),
        ))

        // Upload avatar
        axios(`${process.env.VUE_APP_ROOT_API}utilitaire/media/televerser`, this.config)
          .then((response) => {
            this.chunks.shift()
            this.index += 1

            this.image = response.data.data

            if (response.status === 201) {
              this.updateInput(response.data.data)

              this.resetForm()

              this.initialImage = false
              this.imageUploaded = true
              this.loading = false
              this.modal.addImage = false
              this.isDragging = false
            }
          }).catch((error) => console.log(error))
      }
    },
    updateInput(event) {
      this.handleChange(event)
      this.$emit('update:modelValue', event)
    },
    deleteImage(displayAlert = true) {
      if (this.initialImage) {
        this.updateInput(null)
        this.resetForm()
        this.image = null
      } else {
        this.fetchService.delete(`utilitaire/media/${this.image.id}`).then(() => {
          if (displayAlert) {
            this.emitter.emit('alert', {
              type: 'success',
              content: "L'image a bien été supprimée.",
            })
          }

          this.updateInput(null)
          this.resetForm()
        })
      }
    },
    resetForm() {
      // Set default value and reload UserAvatar component
      this.chunks = []
      if (this.type === 'avatar') {
        this.image = null
      }
      this.index = 1
      this.chunkSize = 0
      this.numberOfChunks = 0
      this.imageUploaded = false
      this.componentKey += 1
    },
  },
}
</script>

<style lang="scss" scoped>
/* INPUT IMAGE */

.input-image {}

.input {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
}

.input-image-input {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
}

.input-image-label {
  display: flex;
  justify-content: center;
  margin-bottom: 0.8rem;
}

.input-text.input-text--info {
  z-index: 1
}

.user-avatar {
  margin-bottom: $gutter-half;
}

.input-image-filename {
  margin-bottom: $gutter-half;

  > span {
    font-family: monospace;
    word-break: break-all;
  }
}

.input-image-btn-add {
  pointer-events: none;
}

.input-image-btn-remove {}

.drop {
  width: 100%;
  height: 100%;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color .2s ease-in-out;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background: transparent;
  opacity: 0;
}

.input.isUploaded .drop {
  z-index: -1;
}

.input.isDragging .drop.isDragging {
  background-color: $color-primary;
  border-width: 2px;
  border-color: #fff;
  border-style: dashed;
  opacity: 1;
  visibility: visible;
  z-index: 1;
}

$image-size: 8rem;

.image {
  display: flex;
  justify-content: center;
  align-items: center;
  @include size($image-size);
  background-position: 50% 50%;
  background-size: cover;
}
</style>
