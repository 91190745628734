<template>
  <div class="svg-chip">
    <div class="svg-chip-content" v-if="media">
      <img class="svg-chip-img" :src="media">
      <div class="svg-chip-btn"
           v-if="!noDeleteButton"
      >
        <Btn
          class=""
          color="default"
          round
          :icon="icon"
          iconSize="sm"
          iconAfter
        />
      </div>
    </div>
    <div class="svg-chip-title">{{ image?.nom }}</div>
  </div>
</template>

<script>
import Btn from '@/components/base/Btn.vue'

export default {
  name: 'SvgChip',

  components: {
    Btn,
  },

  props: {
    image: {
      type: Object,
    },
    icon: {
      type: String,
      default: 'times-circle',
    },
    noDeleteButton: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      media: '',
    }
  },

  watch: {
    // eslint-disable-next-line func-names
    '$props.image': function () {
      if (this.image && 'media' in this.image) {
        this.getMedia()
      }
    },
  },

  mounted() {
    if (this.image && 'media' in this.image) {
      this.getMedia()
    }
  },

  methods: {
    getMedia() {
      this.loading = true

      this.fileService.displayImage(this.image.media.jeton).then((response) => {
        this.media = response.data
      })

      this.loading = false
    },
  },
}
</script>

<style lang="scss" scoped>

.svg-chip {
}

.svg-chip-content {
  width: 9rem;
  position: relative;
  margin-bottom: $gutter-quarter;
  padding: 0.2rem;
  border: 1px solid $color-gray-lighter;
  background-color: white;
  aspect-ratio: 1/1;
}

.svg-chip-img {}

.svg-chip-btn {
  position: absolute;
  top: 0;
  right: 0;
  transform: translate3d(50%, -50%, 0);

  > .btn {
    padding: 0.4rem;
    background-color: white;
    color: $color-gray-light;

    @include hocus() {
      background-color: white;
      color: $color-gray-dark;
    }
  }
}

.svg-chip-title {
  line-height: $line-height-small;
  font-size: $font-size-base;
  font-weight: $font-weight-normal;
  color: $body-color-base;
}

</style>
